.mainPage {
    background: rgb(246,246,248);
    position: absolute;
    color: rgb(80, 80, 80);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
	display: flex;
	flex-direction: column;

    *:focus {
        outline: 0
    }

    .openText {
        color: #07be03;
    }

	.notEnfonts{
	  font-weight: 600;
	}

    .categoriesOutter {
        width: 100%;
        background: #fff;
		display: flex;
		height: 40px;
		align-items: center;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		position: relative;
		.menuIcon{
			width: 46px;
			height: 40px;
			padding-top: 12px;
			padding-left: 16px;
			cursor: pointer;
			img{
				width: 20px;
				height: 20px;
			}
		}
		.categories {
			flex: 1;
		    white-space: nowrap;
		    font-size: 14px;
		    justify-content: flex-start;
		    display: flex;
		    width: 100%;
		    overflow-x: auto;
		    overflow-y: hidden;
			-webkit-overflow-scrolling: touch;
			color: #000;
			>div {
			    padding: 0 10px;
				height: 40px;
				line-height: 40px;
				cursor: pointer;
				position: relative;
				.categorySelect{
				    position: absolute;
					bottom: 0;
					left: 5px;
					right: 5px;
					height: 4px;
					background-color: var(--theme-color);
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;
				}
			}
		}
		.searchBar{
			position: absolute;
			z-index: -1;
			top: 40px;
			left: 0;
			right: 0;
			height: 48px;
			background-color: #fff;
			box-shadow: 0 10px 8px 1px rgba(0,0,0,0.15);
			opacity: 0;
			transition: opacity 0.5s;
			.searchInput{
				position: absolute;
				top: 8px;
				left: 16px;
				right: 16px;
				height: 32px;
				border-radius: 16px;
				background-color: #f2f2f2;
				display: flex;
				align-items: center;
				padding-left: 12px;
				padding-right: 26px;
				input{
					font-size: 14px;
				}
				i{
					font-size: 14px;
				}
				.clearSearchInput{
					width: 40px;
					height: 40px;
					position: absolute;
					right: -2px;
					top: -4px;
					line-height: 40px;
					text-align: center;
					transform: rotate(45deg);
					span{
						font-size: 14px;
					}
				}
				
				input{
					padding-left: 8px;
					border: none;
					background-color: transparent;
					height: 32px;
					width: 0;
					flex:1;
					line-height: 32px;
					&::-webkit-input-placeholder {
						color: #888;
					}
				}
			}
			&.show{
				z-index: 9;
				opacity: 1;
			}
		}
    }

    .itemsContainer {
        position: relative;
        height: 0;
        flex: 1 1 10px;
		.searchLoading{
			z-index: 8;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.2);
			display: flex;
			align-items: center;
			justify-content: center;
			[data-loader=circle] {
			  width: 20px;
			  height: 20px;
			  -webkit-animation: circle infinite .75s linear;
			  -moz-animation: circle infinite .75s linear;
			  -o-animation: circle infinite .75s linear;
			  animation: circle infinite .75s linear;
			  border: 2px solid #fff;
			  border-top-color: transparent;
			  border-radius: 100%
			}
			@keyframes circle {
			  0% {
			    -webkit-transform: rotate(0);
			    -ms-transform: rotate(0);
			    -o-transform: rotate(0);
			    transform: rotate(0)
			  }
			
			  100% {
			    -webkit-transform: rotate(360deg);
			    -ms-transform: rotate(360deg);
			    -o-transform: rotate(360deg);
			    transform: rotate(360deg)
			  }
			}
		}
		
		.contactlessImg {
		    position: relative;
		    width: 100%;
			height: 160px;
		    .shopText {
		        position: absolute;
		        left: 10px;
		        font-weight: 700;
		        max-width: 80%;
		        text-align: left;
		        text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
				bottom: 10px;
				font-size: 24px;
				line-height: 36px;
				max-height: 84px;
				overflow: hidden;
		    }
			.contactImgBg {
				height: 100%;
			    width: 100%;
			    background-repeat: no-repeat;
				background-position: center;
			}
			
		}

        .scrollbar {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 5px;
            z-index: 5;
            overflow-x: hidden;
            overflow-y: auto;
            padding-left: 10px;
			::-webkit-scrollbar {
			    width: 0;
			    height: 0;
			    display: none;
			    -ms-overflow-style: none;
			    overflow: -moz-hidden-unscrollable;
			}

            .scrollbarTrack {
                height: calc(200% - 30px);
                width: 5px;
                position: relative;
				
                .scrollbarThumb {
                    position: absolute;
                    top: calc(50% - 15px);
                    width: 5px;
                    height: 30px;
                    border-radius: 3px;
                    background-color: rgb(0, 0, 0);
                    opacity: 0.2;
                }
            }
        }

		.items {
			width: 100%;
			height: 100%;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			
			.itemListBx {
			    min-height: calc(100% - 190px);
			    width: 100%;
			    margin: 0 auto;
				
				.category {
				    flex: 0 0 100%;
				    display: flex;
				    flex-flow: row wrap;
				    margin-top: 4px;
					transition: margin-top 0.3s;
					
					.itemCategoryHeader {
						width: 100%;
						display: flex;
						justify-content: space-between;
                        align-items: center;
						padding: 12px 0 12px 16px;
						line-height: 40px;
						color: #000;
						transition: all 0.3s;
						
						.itemsHeaderTitle{
							width: 0;
							flex: 1;
							font-size: 18px;
							font-weight: 700;
                            word-break: break-all;
                            line-height: 24px;
						}
                        .catRequired{
                            height: 24px;
                            line-height: 24px;
                            background-color: rgba(244, 67, 54, 0.1);
                            padding: 0 8px;
                            border-radius: 4px;
                            font-size: 14px;
                            font-weight: bold;
                            color: #4f4f4f;
                        }
						.cateogryFoldIcon {
							width: 40px;
							height: 40px;
							display: block;
							transition: all 0.3s;
							text-align: center;
							cursor: pointer;
							i{
								font-size: 28px;
							}
						}
						
					}
					
					&.fold {
						margin-top: 10px;
						.itemCategoryHeader{
							padding: 0px 0 0px 16px;
							.cateogryFoldIcon{
								transform: rotate(-90deg);
							}
						}
					}
					
					.itemsCategoryDescription {
						position: relative;
						line-height: 18px;
						padding: 0 20px 0 25px;
						margin-bottom: 10px;
						font-size: 13px;
						text-align: left;
						flex: 0 0 calc(100% - 20px);
						color: rgb(80, 80, 80);
						max-width: calc(100% - 20px);
						&.limitDesc{
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							word-wrap: break-word;
							font-size: 12px;
							margin-top: -10px;
						}
						&.open {
							-webkit-line-clamp: 20;
						}
					}
					
					.categoryItems{
						display: flex;
						flex-wrap: wrap;
						width: 100%;
						padding: 0 16px;
						background: var(--white-background-color);
					}
					
					.itemBody {
					    min-height: 60px;
					    position: relative;
					    cursor: pointer;
						padding: 8px 0;
						display: flex;
						align-items: center;
						border-bottom: 1px solid rgba(120,120,120,0.1);
						width: 100%;

						&:hover{
							background-color: rgb(251,252,253);
						}
						
						.badge{
							position: absolute;
							right: -15px;
							top: 0;
							height: 30px;
							min-width: 30px;
							font-size: 12px;
							font-weight: bold;
							color: var(--color-btn-text);
							background-color: var(--theme-color);
							border-radius: 15px;
							line-height: 24px;
							text-align: center;
							padding: 0 6px;
							border: 3px solid #fff;
						}
						
						.itemImg {
							width: 86px;
						    height: 86px;
						    border-radius: 5px;
						    margin: 4px 0 4px 20px;
							background-position: center center;
							background-repeat: no-repeat;
							background-size: cover;
						}
						
						.itemInfo {
							flex: 1;
							overflow: hidden;
							font-size: 14px;
							height: 100%;
							display: flex;
							flex-direction: column;
							.itemInfoTop {
								flex: 1;
								.itemName {
								    color: #000;
								    line-height: 24px;
									font-size: 16px;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: 2;
									overflow: hidden;
								}
								.itemDescription {
									word-break: break-word;
								    overflow: hidden;
								    display: -webkit-box;
								    -webkit-line-clamp: 2;
								    -webkit-box-orient: vertical;
									color: #505050;
									line-height: 16.5px;
									margin-top: 4px;
								}
								.itemPrice{
									display: flex;
									margin-top: 5px;
								}
								.strikethroughStyle{
									font-size: 12px;
									line-height: 22px;
									color: #828282;
									font-weight: 400;
									text-decoration: line-through;
									margin-right: 8px;
								}
								.salePrice{
									margin-left: 8px;
									padding: 0 4px;
									font-size: 12px;
									line-height: 22px;
									font-weight: 600;
									background: rgba(255,0,0,0.1);
									border-radius: 5px;
								}
								.itemDisplayPrice {
								    display: flex;
									align-items: center;
									color: #000;
									.soldOut {
									    margin-left: 8px;
									    color:#fff;
										background-color: #a3a4ab;
										padding: 0 5px;
										border-radius: 4px;
										font-size: 12px;
										line-height: 18px;
										height: 18px;
										font-weight: normal;
									}
								}
							}
							
							.itemInfoBottom{
								margin-top: 5px;
								min-height: 20px;
								padding-bottom: 3px;
								display: flex;
								align-items: center;
								flex-wrap: wrap;
								.label{
									height: 20px;
									line-height: 20px;
									text-align: center;
									border-radius: 10px;
									margin-right: 4px;
									margin-bottom: 4px;
									display: flex;
									align-items: center;
									padding: 0 5px 2px 8px;
									font-size: 14px;
									font-weight: 600;
									img{
										width: 14px;
										margin-left: 2px;
									}
									&.reorderLabel{
										background-color: rgba(105, 195, 63, 0.05);
										color: #69C33F;
									}
									&.popularLabel{
										background-color: rgba(244, 67, 54, 0.05);
										color: rgba(244, 67, 54, 1);
									}
									&.newLabel{
										background-color: rgba(255, 182, 0, 0.05);
										color: rgba(242, 110, 33, 1);
									}
									&.coldLabel{
										background-color: rgba(111, 216, 255, 0.05);
										color: rgba(111, 216, 255, 1);
									}
									&.hotLabel{
										background-color: rgba(244, 67, 54, 0.05);
										color: rgba(244, 67, 54, 1)
									}
									&.ayciLabel{
										background-color: rgba(206, 82, 140, 0.05);
										color: rgba(206, 82, 140, 1);
									}
									&.caLabel{
										background-color: rgba(60, 80, 12, 0.05);
										color: rgba(60, 80, 12, 1);
									}
									&.gfLabel{
										background-color: rgba(199, 143, 64, 0.05);
										color: rgba(199, 143, 64, 1);
									}
									&.veganLabel{
										background-color: rgba(137, 156, 55, 0.05);
										color: rgba(137, 156, 55, 1);
									}
									&.recommendedLabel{
										padding: 0 8px 2px 8px;
										background-color: rgba(211, 101, 0, 0.05); 
									}
									&.spicyLabel{
										background-color: rgba(121, 26, 23, 0.05);
										color: rgba(148, 27, 27, 1);
									}
									&.rawLabel{										
										background-color: rgba(194, 22, 12, 0.05); 
										color: rgba(194, 22, 12, 1);

									}
									&.imitationLabel{			
										background-color: rgba(216, 69, 91, 0.05);
										color: rgba(216, 69, 91, 1)
									}
									&.veggieLabel{										
										background-color: rgba(95, 137, 64, 0.05);
										color: rgba(95, 137, 64, 1);
									}
									&.shellfishLabel{				
										background-color: rgba(151, 129, 93, 0.05);
										color: rgba(151, 129, 93, 1);
									}
									&.caffeineLabel{				
										background-color:rgba(95, 137, 64, 0.05);
										color: rgba(155, 78, 0, 1);
										img{
											width: 17px;
											transform: rotate(-7deg);
										}
									}
								}
								.colorBox {
									margin-right: 4px;
									margin-bottom: 4px;
								}
							}
						}
						&.unavailable{
							cursor: default;
						}
					}
				}
			}
			
			.footerBx {
			    height: 60px;
			    margin-top: 100px;
			    width: 100%;
			}
			
		}
		
	
	}

	.promotionSwiper{
		width: 100%;
		height: 148px;
		overflow: hidden;
		.promotionSwiperContainer {
		    margin: 16px 0 0 16px !important;
		    overflow: visible !important;
		    height:126px;		
		    
		}
	}
	.aloneRewardCard{
		margin: 10px 10px 0;
		border: none;
		.swiper-slide {
			width: 100%;	
			.slider-card {
				width: 100%;
				&.loyaltyMemberBx{						
					background: url(../../images/rewardsBg.png) center center no-repeat;
					background-size: cover;
				} 
			}
		}
	}
	.swiper-slide {
		width: 237px;

		.slider-card {
			width: 227px;
			height: 116px;
			display: flex;
			align-items: center;
			background-color: #fff;
			border-radius: 10px;
			color: #000;
			filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
			&.contactlessService{
				align-items: flex-start;
				overflow: auto;
				background: url(../../images/slide_contactless.png) center center no-repeat;
				background-size: cover;
				.slider-cont{
					padding-top: 10px;
					padding-bottom: 10px;
				}
			}
			&.promotion{
				background: url(../../images/slide_promotion.png) center center no-repeat;
				background-size: cover;
			}
			&.loyaltyMemberBx{						
				background: url(../../images/rewardsMenuBg.png) center center no-repeat;
				background-size: cover;
			}    
			.slider-cont{
				font-size: 14px;
				padding: 0 12px;
			}
			.slider-card-left {
				width: 0;
				flex: 1;
				padding-left: 16px;
				padding-right: 16px;
				.slider-name{
					font-size: 16px;
					font-weight: 600;
					height: 24px;
					line-height: 24px;
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.slider-con{
					margin-top: 2px;
					font-size: 14px;
					line-height: 19px;
					width: 100%;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
				}
				
				.slider-foot{
					font-size: 12px;
					color: #828282;
					text-decoration: underline;
					margin-top: 3px;
					line-height: 20px;
					cursor: pointer;
				}						
				.loyaltyMember{
					border-radius: 10px;
					.title{
						font-weight: 600;
						font-size: 16px;
						line-height: 22px;
						letter-spacing: -0.02em;					
						color: #120136;
					}
					.content{
						font-size: 14px;
						line-height: 16px;
						color: #120136;
						span{
							font-weight: 600;
							padding-left: 2px;
							color: #120136;
							&::before{
								content: "";
								display: inline-block;
								width: 16px;
								height: 16px;
								background: url(../../images/subtract.png) 0 0 no-repeat;
								background-size: 100%;
								vertical-align: middle;
								margin-right: 2px;
								margin-top: -2px;
							}				
						}
						.subtract{
							display: inline-block;
							width: 27px;
							height: 27px;
							background: url(../../images/subtract.png) 0 0 no-repeat;
							background-size: 100%;
							vertical-align: middle;
						}
						.rewardsPoints{
							font-weight: 700;
							font-size: 30px;
							line-height: 41px;
							letter-spacing: -0.02em;
							color: #120136;
							margin-left: 8px;
							vertical-align: middle;
						}
					}
					.joinFree{
						text-align: right;
						margin-top: 2px;
						.joinFreeBtn{
							display: inline-block;
							padding: 2px 10px;
							line-height: 18px;
							background: var(--theme-color);
							border-radius: 40px;
							font-size: 12px;
							font-weight: 600;
							text-align: center;
							color: var(--color-btn-text);
							letter-spacing: -0.02em;
							cursor: pointer;
						}
					}
				}
			}

			.slider-card-right {
				width: 50px;
				height: 50px;
				margin: 0 16px;
			}
		}
	}
	.largeWidth{
		width: 50%;
		.slider-card{
			width: calc(100% - 16px);
		}
	}
	.swiperPagination{
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		height: 15px;
		position: initial;
		margin-top: -10px;
		margin-bottom: 0;
		span{
			margin: 0 2px;
			&.bullet{
				background-color: #C4C4C4;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				cursor: pointer;
			}
			&.bulletActive{
				background-color: var(--theme-color);
			}
			&.opacity{
				opacity: 0;
			}
		}
	}
	.transparent{
		span{
			&.bullet{
				background-color: transparent;
			}
		}
	}
	
	.singleBanner{
		width: calc(100% - 16px);
		margin: 16px auto 0;
		min-height: 100px;
		background-color: #fff;
		border-radius: 10px;
		display: flex;
		align-items: center;
		padding: 12px 16px;
		filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
		.bannerContent{
			margin-right: 12px;
			font-size: 12px;
			line-height: 20px;
			color: #000;
			flex: 1;
		}
		.bannerIcon{
			img{
				width: 50px;
				height: 50px;
			}
		}
		&.contactlessBanner{
			background: url(../../images/slide_contactless_2.png) right center no-repeat;
			background-size: cover;
		}
		&.promoctionBanner{
			background: url(../../images/slide_promotion_2.png) right center no-repeat;
			background-size: cover;
			.bannerContent{
				height: 88px;
			}
			.slider-name{
				font-size: 16px;
				font-weight: 600;
				height: 24px;
				line-height: 24px;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.slider-con{
				margin-top: 5px;
				font-size: 12px;
				line-height: 15px;
				height: 30px;
				width: 100%;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
			.slider-foot{
				font-size: 12px;
				color: #828282;
				text-decoration: underline;
				margin-top: 3px;
				line-height: 20px;
				cursor: pointer;
			}
			.bannerContent{
				flex: 1;
			}
		}
	}
	
	.unActiveServiceBx {
	    text-align: center;
	    font-size: 18px;
	    line-height: 200px;
	}
	
	.sendTokitchenOut{
		position: fixed;
		bottom: 120px;
		right: 10px;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		background-color: rgba(0,0,0,0.7);
		color: #fff;
		text-align: center;
		padding-top: 6px;
		.iconKitchen{
			font-size: 24px;
		}
		.sendTokitchenQty{
			display: block;
			position: absolute;
			top: -8px;
			right: -5%;
			height: 16px;
			font-size: 12px;
			line-height: 16px;
			padding: 0 4px;
			background-color: var(--theme-color);
			border-radius: 8px;    
			min-width: 16px;
		}
	}
	
	.menuPanelContainer{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 62;
		display: none;
		.menuPanelWrap{
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0);
			transition: background-color 0.3s;
		}
		.menuPanel{
			position: absolute;
			z-index: 63;
			top: 0;
			left: -180px;
			bottom: 0;
			width: 180px;
			background-color: #fff;
			overflow-y:auto;
			transition: left 0.3s;
			.menuPanelSection{
				margin: 0;
				.menuPanelTitle{
					padding: 8px 6px 8px 16px;
					width: 180px;
					min-height: 40px;
					display: flex;
					align-items: center;
					background-color: #F2F2F2;
					color: #828282;
					cursor: pointer;
					span{
						flex:1;
						line-height: 18px;
						font-size: 13px;
						font-weight: 600;
					}
					i{
						font-size: 24px;
						transition: transform 0.25s;
					}
				}
				.menuPanelItems{
					transition: max-height 0.3s;
					overflow: auto;
					padding-left: 6px;
					margin: 3px 0;
					.menuPanelItem{
						cursor: pointer;
						width: 168px;
						margin:0;
						font-size: 15px;
						font-weight: 600;
						color: #000;
						line-height: 20px;
						padding: 10px 10px 10px 16px;
						position: relative;
						i{
							margin-left: 8px;
							font-size: 14px;
							color: var(--theme-text-color);
						}
						&.active{
							color: var(--theme-text-color);
						}
						&.active:after{
							content:'';
							background-color: var(--theme-color);
							opacity: 0;
							position: absolute;
							top: 0;
							left: 0;
							bottom: 0;
							right: 0;
							opacity: 0.1;
							z-index: -1;
							border-radius: 5px;
						}
					}
				}
				&.collapse{
					.menuPanelTitle{
						i{
							transform: rotate(-90deg);
						}
					}
					.menuPanelItems{
						max-height: 0px !important;
						overflow: hidden;
					}
				}
			}
		}
		&.show{
			.menuPanelWrap{
				background-color: rgba(0,0,0,0.4);
			}
			.menuPanel{
				left: 0;
			}
		}
	}

	.hide{
		display: none !important;
	}

	.lastOrderFab{
		position: absolute;
		top: 22px;
		right: 80px;
		height: 32px;
		border-radius: 16px;
		box-shadow: 0 1px 6px rgba(0,0,0,0.2);
		transition: all 0.2s;
		cursor: pointer;
		background-color: #fff;
		display: flex;
		align-items: center;
		padding: 0 6px 0 18px;
		&:hover{
			box-shadow: 0 2px 10px rgba(0,0,0,0.3);
		}
		span{
			font-size: 14px;
			font-weight: 600;
			color: #48484c;
		}
		svg{
			margin-top: 2px;
			margin-left: 2px;
		}
	}

    .toTop{
        position: fixed;
        bottom: 182px;
        right: -42px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: rgba(0,0,0,0.72);
        text-align: center;
        padding-top: 9px;
        transition: right 0.4s linear;
        img{
            width: 24px;
            height: 24px;
        }
        &.show{
            right: 10px;
        }
    }
}
@keyframes shake {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(15deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-15deg);
    }

    100% {
        transform: scale(0deg);
    }
}

// @media screen and (max-width: 480px){
//     .mainPage{
//         display: block;
//     }
// }

@media screen and (max-width: 768px) {
		.mainPage{
			.items,.itemsContainer{
				::-webkit-scrollbar {
				    width: 0;
				    height: 0;
				    display: none;
				    -ms-overflow-style: none;
				    overflow: -moz-hidden-unscrollable;
				}
			}
			.lastOrderFab{
				top: unset;
				bottom: 152px;
				right: 0;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				height: 28px;
				padding: 0 4px 0 12px;
				background-color: rgba(255,255,255,0.92);
				span{
					font-size: 13px;
					color: #666;
				}
			}
		}
	}
@media screen and (min-width: 769px) {
	.mainPage{
		background-color: #fff;
		padding: 10px 20px 0;
		.reorderPopup{
			min-width: 360px;
		}
		.categoriesOutter{
			border:none;
			border-radius: 5px;
			overflow: hidden;
			box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
			margin-bottom: 24px;
			position: relative;
			.arrowRight{
				width: 40px;
				height: 40px;
				text-align: center;
				padding-top: 10px;
				position: relative;
				cursor: pointer;
				img{
					width: 20px;
					height: 20px;
				}
			}
			.arrowRight::after{
				content:' ';
				position: absolute;
				width: 30px;
				top: 2px;
				bottom: 2px;
				left: -30px;
				background-image: linear-gradient(to right, rgba(255,255,255,0) , rgba(255,255,255,1));
			}
			.arrowLeft{
				width: 40px;
				height: 40px;
				text-align: center;
				padding-top: 11px;
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 40px;
				background-color: #fff;
				z-index: 6;
				display: none;
				img{
					width: 20px;
					height: 20px;
					transform: rotate(180deg);
				}
			}
			.arrowLeft::after{
				content:' ';
				position: absolute;
				width: 30px;
				top: 2px;
				bottom: 2px;
				right: -30px;
				background-image: linear-gradient(to left, rgba(255,255,255,0) , rgba(255,255,255,1));
			}
			::-webkit-scrollbar {
				width: 0;
				height: 0;
				display: none;
				-ms-overflow-style: none;
				overflow: -moz-hidden-unscrollable;
			}
		}
		.menuPanelContainer{
			position: absolute;
			overflow: hidden;
		}

		.mainContainer{
			position: relative;
			height: 0;
			flex: 1;
			display: flex;
			flex-direction: column;
			.itemsContainer{
				width: calc(100% - 360px);
				
				.searchLoading{
					background-color: rgba(255,255,255,0.8);
					[data-loader=circle] {
					  border: 2px solid rgba(0,0,0,0.5);
					  border-top-color: transparent;
					}
				}
				
				.contactlessImg{
					height: 250px;
					padding: 0 5px;
					.contactImgBg{
						border-radius: 5px;
						overflow: hidden;
						background-position: center;
					}
					.shopText{
						left: 15px;
						font-size: 30px;
						line-height: 40px;
					}
				}
				.items{
					width: calc(100% - 10px);
					
				}
			}
			.bagContainer{
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				width: 360px;
				display: flex;
				flex-direction: column;
				.promotionSwiper{
					width: 360px;
					height: 110px;
					border-radius: 5px;
					border: 1px solid #e4e4e4;
					overflow: hidden;
					.promotionSwiperContainer{
						margin: 0 !important;
						width: 360px;
						height: 110px;
						
					}
				}
				.aloneRewardCard{
					margin: 0;
					border: 1px solid #e4e4e4;
					border-radius: 10px;
					.swiper-slide{
						width: 358px;
						height: 110px;
						.slider-card{
							width: 358px;
						}
					}
				}
				.swiper-slide{
					width: 360px;
					height: 110px;
					.slider-card{
						width: 360px;
						height: 110px;
						border-radius: 10px;
						filter: none;
						&.contactlessService{
							background-position: center right;
							background-size: contain;
						}
						&.promotion{
							background-position: center right;
							background-size: contain;
						}
						&.loyaltyMemberBx{						
							background-position: center right;
							background-size: contain;
							.loyaltyMember{
								.title{
									margin-bottom: 8px;
								}
								.content{
									line-height: 19px;
								}
							}
						} 
						.slider-card-left{
							.slider-name{
								height: 30px;
								line-height: 30px;
								font-weight: 600;
								font-size: 16px;
							}
							.slider-con{
								font-size: 14px;
								line-height: 20px;
								height: 40px;
							}
						}
						.slider-cont{
							
						}
					}
				}
				.swiperPagination{
					margin-top: 0;
					margin-bottom: 10px;
				}
				
				.singleBanner{
					width: 360px;
					border: 1px solid rgba(0,0,0,0.1);
					padding: 16px 20px;
					margin: 0;							
					filter: none;			
					.bannerContent{
						font-size: 14px;
						line-height: 24px;
					}
					&.contactlessBanner{
						background-size: contain;
					}
					&.promoctionBanner{
						height: 88px;
						background-size: contain;
						.bannerContent{
							height: 88px;
							padding-top: 5px;
						}
						.slider-foot{
							margin: 0;
						}
					}
				}
				
				.bagPanel{
					height: 0;
					flex: 1;
				}
				
			}
		}
		
		.menuPanelContainer{
			.menuPanel{
				width: 190px;
				left: -190px;
			}
		}
	}
	
}

@media screen and (min-width: 1200px) {
	.mainPage{
		padding-left: 70px;
		padding-right: 70px;
		max-width: 1480px;
		margin: 0 auto;
		.itemsContainer{
			.items{
				.itemListBx{
					.category{
						margin-top: 20px;
						.itemCategoryHeader{
							padding: 0 0 5px 5px;
						}
						.categoryItems{
							padding: 0 5px 0 0;
							justify-content: space-between;
						}
						.itemBody{
							width: calc(50% - 10px);
							border: 1px solid #e4e4e4;
							border-radius: 5px;
							margin-bottom: 20px;
							padding: 10px;
							.badge{
								right: 0px;
							}
							.itemInfo{
								.itemInfoTop{
									font-size: 16px;
									font-weight: 500;
									.itemDescription{
										font-size: 14px;
										line-height: 18px;
										margin-bottom: 4px;
										margin-top: 8px;
									}
								}
							}
						}
					}
				}
			}
		}
		
	}
}