.orderTypesCon{
	border-top: 1px solid rgba(255,220,200,0.1);
	border-bottom: 1px solid rgba(255,220,200,0.1);

	.orderTypes{
		overflow: hidden;
		display: flex;
		align-items: center;
		padding: 7px 10px 7px 4px;
		background: linear-gradient(90deg, rgba(255, 226, 89, 0.1) 0%, rgba(255, 167, 81, 0.1) 100%), #FFFFFF;
		cursor: pointer;
		i.icon{
			color: #FFB600;
			margin-right: 4px;
			font-size: 36px;
		}
		.infos{
			flex: 1;
			h3{
				font-size: 14px;
				line-height: 16px;
				margin: 0 0 5px 0;
				font-weight: 600;
				color: #333;
				display: flex;
				align-items: center;
				span{
					background-color: #FFB600;
					width: 4px;
					height: 4px;
					border-radius: 2px;
					margin: 3px 6px 0;
				}
			}
			p{
				font-size: 12px;
				line-height: 16px;
				margin: 0;
				color: #454545;
			}
		}
		i.arrow{
			color: #FFB600;
			font-size: 18px;
		}
		&.closed{
			background: linear-gradient(90deg, #5124A5 0%, #41147A 36.46%, #2A0845 100%);
			cursor: default;
			.infos{
				h3{
					color: #fff;
				}
				p{
					color: rgba(255,255,255,0.9);
				}
			}
		}
	}
	
	.orderTypesDialogWrap{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 101;
		background-color: rgba(0,0,0,0.75);
		opacity: 0;
		transition: opacity 0.3s;
		.orderTypesDialog{
			position: absolute;
			z-index: 102;
			border-radius: 10px;
			padding: 0 8px 1px;
			width: 92%;
			max-width: 440px;
			background-color: #fff;
			transition: all 0.25s;
			&.stick{
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				max-height: calc(100% - 80px);
				display: flex;
				flex-direction: column;
			}
			&.center{
				top: 42%;
				left: 50%;
				transform: translate(-50%,-50%);
				.header{
					margin-bottom: 10px;
				}
			}
			.header{
				position: relative;
				padding: 0 8px;
				.restaurantName{
					line-height: 22px;
					font-size: 18px;
					font-weight: 600;
					color: #333;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 18px 20px 0;
					letter-spacing: -0.02em;
				}
				.closeBtn{
					width: 34px;
					height: 60px;
					position: absolute;
					right: 0px;
					top: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					.icon{
						font-size: 14px;
						color: #333;
					}
				}
				.address{
					padding: 5px 12px 0;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #828282;
					font-size: 12px;
					line-height: 14px;
					.icon{
						margin-right: 3px;
						font-size: 14px;
					}
				}
			}
			
			.typesContainer{
				padding: 14px 10px 0;
				.switchTypes{
					height: 50px;
					border-radius: 15px;
					color: #120136;
					position: relative;
					border: 2px solid #f2f2f2;
					transition: all 0.3s;
					.slider{
						position: absolute;
						top: -2px;
						left: -2px;
						bottom: -2px;
						right: -2px;
						z-index: 2;
						width: 50%;
						transition: all 0.3s;
						border-radius: 15px 0 0 15px;
						border: 2px solid #f2f2f2;
						&.right{
							left: calc(50% + 2px);
							border-radius: 0 15px 15px 0;
						}
						&.activeSlider{
							background-color: var(--theme-opacity-color);
							border: 2px solid var(--theme-color);
						}
					}
					.types{
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						z-index: 3;
						display: flex;
						align-items: center;
						.type{
							flex:1;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							font-size: 14px;
							font-weight: 600;
							transition: all 0.3s;
							cursor: not-allowed;
							height: 50px;
							&:nth-child(1){
								border-top-left-radius: 15px;
								border-bottom-left-radius: 15px;
							}
							&:nth-child(2){
								border-top-right-radius: 15px;
								border-bottom-right-radius: 15px;
							}
							.typePreOrder{
								font-size: 12px;
								color: #4F4F4F;
								font-weight: normal;
							}
							.typeClose{
								font-size: 12px;
								color: #F44336;
								font-weight: normal;
							}
							&.hover{
								cursor: pointer;
							}
						}
					}
					
					&.btnshine{
						animation: btnshine1 1s linear 0.1s;
						.slider{
							animation: btnshine2 1s linear 0.1s;
						}
					}
					
					@keyframes btnshine1{
						0% {
							border-color: #f2f2f2;
							box-shadow: 0 0 0 var(--theme-color);
						}
						35%{
							border-color: var(--theme-color);
							box-shadow: 0 0 8px var(--theme-color);
						}
						65%{
							border-color: var(--theme-color);
							box-shadow: 0 0 8px var(--theme-color);
						}
						100%{
							border-color: #f2f2f2;
							box-shadow: 0 0 0 var(--theme-color);
						}
					}
					@keyframes btnshine2{
						0% {
							border-color: #f2f2f2;
						}
						35%{
							border-color: var(--theme-color);
						}
						65%{
							border-color: var(--theme-color);
						}
						100%{
							border-color: #f2f2f2;
						}
					}
				}
				
				.selectOrderTip{
					font-size: 12px;
					color: #F44336;
					line-height: 14px;
					padding-left: 3px;
					padding-top: 5px;				
				}
			}
			
			.typeInfo{
				overflow: auto;
				padding: 0 13px;
				flex: 1;
				
				.preOrderTip{
					background: rgba(244, 67, 54, 0.05);
					border-radius: 5px;
					color: #F44336;
					padding: 6px 12px;
					font-size: 12px;
					line-height: 16px;
					margin-top: 8px;
					margin-bottom: 4px;
				}
				
				.title{
					color: #333;
					font-size: 14px;
					font-weight: 600;
					line-height: 16px;
					padding-top: 12px;
				}
				
				.dialogTimeSelect{
					padding-top: 6px;
				}
				
				.forms{
					padding-top: 8px;
				}
			}
			.bigbtn{
				background-color: var(--theme-color);
				color: var(--color-btn-text);
				height: 40px;
				line-height: 40px;
				text-align: center;
				border-radius: 20px;
				margin-top: 20px;
				font-size: 16px;
				font-weight: 600;
				cursor: pointer;
				transition: all 0.25s;
				margin-bottom: 20px;
				&.normal:hover{
					box-shadow: 0 0 10px rgba(0,0,0,0.22);
				}
				&.grey{
					background-color: #e0e0e0;
					color: #fff;
				}
			}
			.loginTip{
				padding-bottom: 12px;
				margin-top: -6px
			}
		}
	
		&.show{
			opacity: 1;
			.orderTypesDialog{
				&.stick{
					top: 40px;
				}
				&.center{
					top: 50%;
				}
			}
		}
		&.hide{
			opacity: 0;
		}
	}
}

@media screen and (min-height:680px){
	.orderTypesCon{
		.orderTypes{
			padding: 8px 12px 8px 10px;
		}
		.orderTypesDialogWrap{
			.orderTypesDialog{
				.typeInfo{
					.title{
						font-size: 15px;
					}
					.forms{
						input,.row input{}
					}
				}
				&.stick{
					max-height: calc(100% - 100px);
				}
			}
			&.show{
				.orderTypesDialog{
					&.stick{
						top: 60px;
					}
				}
			}
		}
	}
}

@media screen and (min-height:820px){
	.orderTypesCon{
		.orderTypesDialogWrap{
			.orderTypesDialog{
				padding: 0px 20px 10px;
				.header .closeBtn{
					right: -6px;
				}
				.typesContainer{
					padding: 25px 10px 8px;
				}
				.typeInfo{
					.title{
						padding-top: 16px;
						font-size: 16px;
					}
					.forms{
						input,.row input{}
					}
					.bigbtn{
						height: 44px;
						line-height: 44px;
						font-size: 17px;
					}
				}
				&.stick{
					top: 8%;
				}
			}
			&.show .orderTypesDialog.stick{
				top: 14%;
			}
		}
	}
}

@media screen and (max-width: 480px){
	.orderTypesCon{
		.orderTypes{
			padding: 7px 10px 7px 4px;
		}
	}
}

@media screen and (min-width: 800px){
	.orderTypesCon{
		.orderTypes{
			i.icon{
				font-size: 48px;
			}
		}
		.infos{
			.closeTime{
				display: flex;
				p{
					margin-right: 8px;
				}
			}
		}
	}
}
