.cardManager{
	height: 100%;
	display: flex;
	flex-direction: column;
	.paymentListTitle{
		height: 70px;
		line-height: 70px;
		font-size: 16px;
		font-weight: 600;
		padding-left: 20px;
		margin-top: -50px;
	}
	.paymentList{
		height: 0;
		flex: 1;
		overflow: auto;
		padding: 0 24px;
		.paymentItemBx{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 6px 8px;
			cursor: pointer;
			.cardInfo{
				border-radius: 5px;
				.cardIcon{
					width: 24px;
					height: 24px;
				}
			}
			.expireDate{
				font-size: 14px;
			}
			&:hover{
				background-color: var(--theme-opacity-color);
			}
			&.active{
				color: var(--theme-text-color);
			}
			&.paymentItemBxDisable{
				color: #aaa;
				label{
					span{
						color: #aaa;
						border-color: #aaa;
					}
				}
				&:hover{
					background-color: transparent;
					cursor: default;
				}
			}
		}
		.addNewCard{
			margin: 20px 0;
			color: var(--theme-text-color);
			font-size: 16px;
			height: 36px;
			line-height: 36px;
			border-radius: 20px;
			border: 1px solid var(--theme-color);
			text-align: center;
			width: 240px;
			cursor: pointer;
		}
	}
	.paymentConfirmBx{
		height: 70px;
		padding: 15px 20px;
		box-shadow: 0 -1px 5px rgba(0,0,0,0.1);
		.confirmBtn{
			margin: 0 auto;
			height: 40px;
			line-height: 40px;
			background-color: var(--theme-color);
			text-align: center;
			color: #fff;
			border-radius: 20px;
			cursor: pointer;
		}
	}
}

.paymentPanelStripeInner{
  background: #fff;
}

.paymentPanelBody input{
  border-radius: 0;
}
.cardHolder,.cardExpireDate,.cardCVV,.zipCodeBx{
  display: inline-block;
  width: 48%;
}
.cardHolderError{
  width: 48%;
}
.zipCodeBx, .cardExpireDate{
  margin-left: 4%;
}
.expireDateError{
  width: 48%;
  margin-left: 4%;
}
.cvvError{
  width: 48%;
}
.zipCodeError{
  width: 48%;
  margin-left: 4%;
}
.cardInfoLine{
  width: 100%;
  margin-top: 5%;
}
 
.cardNoBx{
  width: 100%;
  margin-top: 5%;
  display: flex;
}
.customInput, .errorCustomInput{
  width: 100%;
  height:30px;
  font-size:14px;
  border: none;
  outline: none;
  background: #ededed;
  padding: 2px 5px;
}

.cardType{
  display: inline-block;
  text-align: center;
  width: 60px;
  height: 32px;
  line-height: 32px;
  background: lightgray;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}
.errorText{
  display: inline-block;
  text-align: left;
  color: red;
  font-size: 16px;
}
.errorTextBx{
  display: flex;
}
.cardNoError{
  margin-left: 53px;
}

@media only screen and (max-width: 480px){
  .paymentPanelInner{
	width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    font-size: 16px;
  }
  .saveBtn{
    width: 100%;
    margin-top: 10%;
  }
  .customInput, .errorCustomInput{
    font-size: 16px;
  }
}
@media only screen and (min-width: 481px){
  .paymentPanelInner{
    left: calc(50% - 230px);
    width: 342px;
	margin: 0 auto;
    top: 17%;
    bottom: 17%;
  }
  .saveBtn{
    width: 60%;
    margin-top: 10%;
    margin-left: 20%;
  }
  .cardManager{
	  .paymentConfirmBx{
		  .confirmBtn{
			  font-size: 16px;
		  }
	  }
  }
}
