.onBoardPointsPopup{
	max-width: 520px;
	padding-bottom: 30px;
	.bigTitle{
		font-size: 30px;
		font-weight: bold;
		text-align: center;
		padding-top: 30px;
		padding-bottom: 12px;
	}
	.section{
		text-align: center;
		padding: 20px 20px 0;
		.mainPic{
			width: 160px;
		}
		.title{
			padding-top: 25px;
			font-size: 18px;
			font-weight: 600;
			color: #333;
			line-height: 26px;
		}
		.subTitle{
			font-size: 16px;
			color: #828282;
			line-height: 24px;
			padding: 10px 0 25px;
		}
		.points{
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 0 20px;
			.pointStar{
				width: 27px;
			}
			.num{
				font-size: 40px;
				color: #120136;
				padding: 0 8px;
			}
			.unit{
				font-size: 14px;
				color: #120136;
			}
		}
	}
	.closeBtn{
		width: 282px;
		margin: 20px auto 0;
	}
}

@media screen and (max-width: 480px){
	.onBoardPointsPopup{
		min-height: 86vh;
		max-height: 96vh;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		overflow: auto;
		.section .subTitle{
			padding: 18px 0 28px;
		}
	}
}
@media screen and (min-width: 481px){
	.onBoardPointsPopup{
		min-width: 460px;
		padding: 30px 40px 40px;
	}
}