.landingPageWrap{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.7);
	z-index: 210;
	display: none;
	&.show{
		display: block;
		transition: all 0.22s linear;
	}
	&.hide{
		opacity: 0;
	}

	.landingPageCon{
		width: 100%;
		height: 100%;
		background-color: #fff;
		position: relative;
		
		.downloadApp{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: 82;
			transform: translateY(-100%);
			transition: transform 0.4s;
			background-color: rgba(255,255,255,0.96);
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			.app{
				padding: 8px 12px 6px 0;
				display: flex;
				align-items: center;
				.closeBtn{
					width: 42px;
					height: 42px;
					display: flex;
					align-items: center;
					justify-content: center;
					i{
						font-size: 12px;
						color: #787878;
					}
				}
				img.icon{
					width: 38px;
					height: 38px;
					border-radius: 6px;
				}
				.infos{
					padding-left: 12px;
					flex: 1;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.name{
						font-size: 14px;
						color: #232323;
						line-height: 16px;
					}
					.get{
						font-size: 12px;
						margin-top: 6px;
						line-height: 14px;
					}
					.view{
						height: 25px;
						line-height: 25px;
						padding: 0 15px;
						border-radius: 12px;
						background-color: #007aff;
						color: #fff;
						font-size: 14px;
					}
				}
			}
			.descCon{
				padding: 0 12px;
				.desc{
					padding: 9px 0 11px;
					border-top: 1px solid rgba(0,0,0,0.1);
					font-size: 12px;
					line-height: 16px;
					color: #444;
				}
			}
			&.show{
				transform: translateY(0);
				box-shadow: 0 0 8px rgba(0,0,0,0.3);
			}
		}
		.landingPage{
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			.pic{
				height: 60vw;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
			}
			.infos{
				height: 0;
				flex: 1;
				display: flex;
				flex-direction: column;
				padding-top: 16px;
				padding-bottom: 24px;
				&.noPic{
					justify-content: center;
				}
				.logo{
					width: 78px;
					height: 78px;
					display: block;
					border-radius: 50%;
					margin: 0 auto 8px;
					flex-shrink: 0;
				}
				.name{
					font-size: 18px;
					font-weight: 600;
					color: #333;
					text-align: center;
					line-height: 24px;
					flex-shrink: 0;
					margin-bottom: 20px;
				}
				.notice{
					min-height: 40px;
					margin-bottom: 40px;
					overflow: auto;
					max-height: calc(100% - 220px);
					line-height: 22px;
					padding: 0 20px;
					font-size: 15px;
					p{
					  margin: 8px 0;
					  display: flex;
					  b{
					    margin-right: 6px;
						font-weight: bold;
					  }
					}
				}
				.btns{
					flex-shrink: 0;
				}
				.btn{
					flex-shrink: 0;
					width: 72%;
					max-width: 270px;
					margin: 0 auto 16px;
					transition: all 0.25s;
					&:hover{
						box-shadow: 0 0 10px rgba(0,0,0,0.25);
					}
					&.outline{
						max-width: 268px;
						border: 1px solid #95919a;
						color: #120136;
						line-height: 38px !important;
					}
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}
	
		.apkOptionsWrap{
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.42);
			z-index: 84;
			.apkOptions{
				background-color: #fff;
				height: 160px;
				position: absolute;
				bottom: -160px;
				left: 0;
				right: 0;
				transition: bottom 0.3s linear;
				border-top-left-radius: 12px;
				border-top-right-radius: 12px;
				padding-top: 32px;
				&.show{
					bottom: 0;
				}
				.apkOption{
					width: 260px;
					height: 38px;
					border: 1px solid #787878;
					margin: 0 auto 20px;
					border-radius: 10px;
					line-height: 36px;
					display: flex;
					align-items: center;
					padding-left: 40px;
					font-size: 16px;
					img{
						width: 24px;
						height: 24px;
						margin-right: 12px;
					}
				}
			}
			.loading{
				width: 60px;
				height: 60px;
				position: absolute;
				top: calc(50% - 30px);
				left: calc(50% - 30px);
				background-color: #fff;
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				img{
					width: 40px;
					height: 40px;
					animation: loadingRound 0.9s linear infinite;
				}
			}
		}
	}
	
	@media screen and (max-height:670px){
		.landingPageCon{
			.landingPage{
				.pic{
					height: 50vw;
				}
				.infos{
					height: calc(100% - 50vw);
				}
			}
		}
	}
	
	@media screen and (min-width:768px) {
		.landingPageCon{
			&.noPic{
				max-width: 80%;
				max-height: 80%;
				margin: 10% auto 0;
				border-radius: 20px;
			}
			.landingPage{
				flex-direction: row;
				.pic,.infos{
					flex: 1;
					height: 100%;
					justify-content: center;
					.name{
						margin-bottom: 30px;
					}
					.notice{
						max-height: calc(100% - 360px);
						font-size: 16px;
						line-height: 24px;
						padding: 0px 32px;
					}
				}
			}
		}
	}
	@media screen and (min-width:1080px) {
		.landingPageCon{
			border-radius: 20px;
			width: 80%;
			height: 72%;
			max-width: 1200px;
			max-height: 720px;
			overflow: hidden;
			position: absolute;
			top:50%;
			left:50%;
			transform: translate(-50%,-50%);
			box-shadow:  0 0 12px rgba(0,0,0,0.3);
			&.noPic{
				max-width: 800px;
				margin: 0;
			}
		}
	}
}