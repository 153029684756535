.pickupOptionPage{
    height: 100%;
    background-color: #F2F2F2;
    .pickupOptions{
        height: 100%;
        overflow: auto;
        padding: 16px 16px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .section{
            background-color: #fff;
            border-radius: 10px;
            padding: 8px 16px;
            width: 100%;
            max-width: 580px;
            margin: 0 auto;
            .sectionTitle{
                font-size: 17px;
                font-weight: 600;
                line-height: 32px;
                color: #333;
            }
            .options{
                padding-top: 20px;
                padding-bottom: 10px;
                .optionItem{
                    display: flex;
                    cursor: pointer;
                    .iconCon{
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: #f9f9f9;
                        margin-right: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        i{
                            font-size: 40px;
                            color: #ffb600;
                            margin-bottom: 2px;
                        }
                        img{
                            width: 26px;
                            height: 26px;
                            margin-top: 1px;
                        }
                    }
                    .optionInfo{
                        flex: 1;
                        .optionTitle{
                            font-size: 15px;
                            font-weight: 500;
                            line-height: 24px;
                            margin-bottom: 3px;
                        }
                        p{
                            margin: 0;
                            font-size: 13px;
                            line-height: 20px;
                            color: #333;
                        }
                    }
                    .checkedIcon{
                        padding-top: 10px;
                        padding-right: 5px;
                        color:  var(--theme-color);
                        display: none;
                    }
                    &:nth-child(1){
                        padding-bottom: 16px;
                        margin-bottom: 16px;
                        border-bottom: 1px solid #efefef;
                    }
                    &.checked{
                        .iconCon{
                            box-shadow: 0 0 1px 1px var(--theme-color);
                        }
                        .checkedIcon{
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .bottomBtnCon{
        padding: 15px 0;
        .bottomBtn{
            height: 46px;
            line-height: 46px;
            width: calc(100% - 30px);
            max-width: 420px;
            margin: 0 auto;
            background-color: var(--theme-color);
            border-radius: 10px;
            box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
            cursor: pointer;
            color: var(--color-btn-text);
            font-weight: 600;
            font-size: 17px;
            text-align: center;
            cursor: pointer;
            &.disbaled{
                background-color: #ccc;
                color: #454545;
                cursor: not-allowed;
            }
        }
    }
    .tableInputContainer{
        width:460px;
        max-width: 100%;
        padding: 15px 18px 12px;
        .tableInputTitle{
            font-size: 17px;
            font-weight: 600;
            line-height: 32px;
            color: #333;
            margin-bottom: 12px;
        }
        .tableInputDesc{
            font-size: 14px;
            color: #454545;
        }
        .tableNameInput{
            width: 160px;
            height: 120px;
            border: 2px solid var(--theme-color);
            border-radius: 4px;
            margin: 20px auto;
            display: block;
            text-align: center;
            font-size: 38px;
            font-weight: bold;
            &.small{
                font-size: 28px;
            }
        }
    }
}