@import "../../constants/global.scss";
.payNowPage{
	max-width: 800px;
	margin: 0 auto;
	background-color: #f2f2f2;
	height: 100%;
    .checkoutBx{
        padding: 2px 16px 20px;
    }
}

.selectedPaymentTips{
    display: flex;  
    font-size: 12px;
    color: #333;
    line-height: 20px;
    padding: 10px 5px 0;
    vertical-align: middle;
    word-wrap: break-word;
    i{
        font-style: normal;
    }
    .note{
        color: var(--theme-text-color);
        margin-right: 4px;
    }
    b{
        color: var(--theme-text-color);
    }
}


.loyaltyMember{
    padding: 12px 24px;
    background: #fff;
    border-radius: 10px;
    background: url(../../images/rewardsBg.png) center center no-repeat;
    background-size: cover;
    box-shadow: 0px 2px 10px rgba(18, 1, 54, 0.1);
    margin-top: 12px;
    .title{
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.02em;					
        color: #120136;
        margin-bottom: 8px;
    }
    .content{
        font-size: 14px;
        line-height: 19px;
        color: #120136;
        min-height: 40px;
        span{
            font-weight: 600;
            padding-left: 2px;
            color: #120136;
            &::before{
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                background: url(../../images/subtract.png) 0 0 no-repeat;
                background-size: 100%;
                vertical-align: middle;
                margin-right: 2px;
                margin-top: -2px;
            }				
        }
    }
    .joinFree{
        text-align: right;
        .joinFreeBtn{
            display: inline-block;
            width: 70px;
            height: 22px;
            line-height: 22px;
            background: var(--theme-color);
            border-radius: 40px;
            font-size: 12px;
            font-weight: 600;
            text-align: center;
            color: var(--color-btn-text);
            letter-spacing: -0.02em;
            cursor: pointer;
        }
    }
}
.delayTimeOuter{    
    @include floatPanel;
    z-index: 62;
}
.delayTimeInner{    
	@include floatPanelInner;
	z-index: 62;		
	padding: 32px 24px 24px 24px;
	border-radius: 10px 10px 0 0;
	text-align: left;
	display: flex;
	flex-direction: column;
	width: auto;   
	height: auto;
	max-height: 80%;
	left: 0;
	right: 0;
	bottom: 0;
	top: auto;
	margin-top: 0;
	margin-left: 0;
	.otherLanguage{
		font-weight: 600!important;
	}
	.floatPanelHeader{
		width: 100%;
		height: 40px;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
	}
	.closeBtn{
		width: 50px;
		height: 50px;
		float: right;
		cursor:pointer;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 20px;
	}
	.delayTimeTitle{
		line-height: 23px;
		margin: 0;
		font-size: 17px;
		font-weight: normal;
		letter-spacing: -0.02em;
	}
	.delayTimeMain{
		flex: 1;
		overflow: auto;
	}
	.delayTimeCont{
		margin: 16px 0 0;
		dt{
			line-height: 22px;
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 5px;
		}
		dd{
			margin-left: 0;
			display: flex;
			align-items:flex-start;
			font-size: 16px;
			line-height: 22px;
		}
		.circle{
			display: inline-block;
			width: 4px;
			height: 4px;
			margin-top: 9px;
			border-radius: 4px;
			background: #4f4f4f;
			margin-right: 5px;
		}
		.reedemDiscountCont{
			display: block;
			padding-bottom: 12px;
			span{
				color: var(--theme-color);
			}
		}
	}
	.delayTimeFoot{
		display: flex;
		justify-content: space-between;
		margin: 16px 10px 0 10px; 
		.confirmBtn{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px!important;
			line-height: 38px!important;
			background: var(--theme-color);
			font-size: 16px;
			border-radius: 10px;
			text-align: center;
			color: #120136;			
			border: none;
			cursor: pointer;
		}
	}
	.delayTimeFoot>div,.delayTimeFoot>button{
		width: calc(50% - 8px);
		color: #120136;
		font-size: 16px;
        padding: 0 10px;
	}
}

.paymentNotShowBtn{
    display: none;
}
.payDetailPage{
    display: flex;
    flex-direction: column;
}
.showPaymentToTop{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.payNowDetailPage{
    flex: 1;
    overflow: auto;
}
.mobileSubmitBxSDF{
    position: fixed;
    bottom: 0;
    left:0;
    padding: 0;
    width: 100%;
	background-color: rgba(242,242,242,0.5);
    height: 72px;
    padding: 9px 16px 0;
    &.btnHidden{
        display: none;
    }
} 

.checkoutTxt{
    font-size: 24px;
    font-weight: bold;
    line-height: 42px;
}

.mobileTotal{
    display: inline-block;
    width: 20%;
    height: 42px;
    line-height: 42px;
    font-size: 16px;
}

.cardBx{
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 16px;
    box-sizing: border-box;
    padding: 8px 16px;
}
.cardTitle{
    font-size: 17px;
    font-weight: 600;
    line-height: 28px;

    span.tipAmount{
        float: right;
    }
}

.tipListBx{
    display: flex;
    flex-wrap: wrap;
    .tipSuggestionBtn{
        margin-right: 12px;
        margin-bottom: 3px;
        &:last-child{
            margin-right: 0;
        }
        .tipSuggestionInner{
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 20px;
            color: #000;
            border-radius: 18px;
            border: 1px solid var(--theme-color);
            height: 36px;
            min-width: 50px;
            &:hover{
                box-shadow: 0 1px 2px rgba(0,0,0,0.2);
            }
            .othersTipBtn{
                font-size: 12px;
                line-height: 14px;
                padding: 0 7px;
            }
            &.selectedBtn{
                background-color: var(--theme-color);
                color: var(--color-btn-text);
                box-shadow: 0 1px 2px rgba(0,0,0,0.2);
            }
        }
    }
}

.cardContent{
    box-sizing: border-box;
    padding: 12px 0;
    font-size: 14px;
}
.itemInfoBx{
    padding: 0.4rem 0;
    font-size: 15px;
}
.itemInfoBx:last-child{
    border-bottom: 1px solid #d1d1d1;
}
.itemInfo{
    display: flex;
    .itemName{
        width: 60%;
    }
    .itemQty{
        width: 15%;
        text-align: right;
        color: #999;
    }
    .itemPrice{
        width: 25%;
        margin-top: 0;
        color: #555;
    }
    .itemName,.itemQty,.itemPrice{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.optionList{
    width: 80%;
    color: #aba9a9;
    margin-top: 0.2rem;
    font-size: 13px;
    div{
        display: inline-block;
    }
}
.optionList:last-child{
    margin-bottom: 20px;
}
.itemPrice{
    word-break: break-all;
    text-align: right;
    color: #777;
    margin-top: 0.4rem;
}
.choosingPaymentCard{
    position: relative;
    z-index: 1;
}
.itemContentLine{
  height: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555555;
}
.itemContentKey{
	flex: 1;
	display: flex;
	align-items: center;
    padding-left: 3px;
	.iconInfo{
		color: #6a6a6e;
		margin-left: 5px;
		margin-right: 5px;
		cursor: pointer;
		font-size: 13px;
	}
}
.itemContentValue{
	padding-right: 3px;
}
.discountColor{
    color: var(--theme-color);
}
.itemTotalBx{
    border-top: 1px solid var(--border-color);
    margin-top: 2px;
    .itemContentKey,.itemContentValue{
        font-size: 15px;
    }
}					
.summarySavingMoney{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
    font-size: 14px;
    .savingIcon{
        width: 20px;
        height: 20px;
    }
    .savingCont{
        padding-top: 4px;
        padding-left: 6px;
        color: var(--theme-color);
        font-weight: 600;
    }
}
.summaryEarnPoints{
    display: flex;
    justify-content: flex-end;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #120136;
    margin-top: 5px;
    span{
        color: var(--theme-color);
    }
    .subtract{
        font-size: 16px;
        color: var(--theme-color);
        margin-right: 4px;
    }
}

.itemContentTips{
    font-weight: normal;
    font-size: 14px;
    color: rgb(160,160,160);
}
.tableInfoCon{
	padding: 12px 0px 0px;
}
.tableUserInfo{
    color: #526177;
    text-align: center;
    margin: 0.24rem auto 0.24rem;
    .totalTableUser{
        font-size: 18px;
        line-height: 1rem;
        color: #4bb43f;
        text-align: center;
        margin-bottom: 0.4rem;
    }
    .stillHaveToPay{
        margin-bottom: 0.6rem;
    }
    .payImg{
        text-align: center;
        margin-top: 0.6rem;
        img{
            width: 1.8rem;
        }
    }
}
.payFailed .totalTableUser{
    color: #fd6c14;
}
.orderNumber{
    margin-top: 0.3rem;
}
.backToPay{
    width: 6.36rem;
    padding-top: 0.8rem;
    margin: 2rem auto 0;
}
.footerBx{
    height: 60px;
    margin-top: 20px;
    width: 100%;
}
.validationText{
    padding-left: 10px;
    font-size: 14px;  
    color: #C30052;
}
.topTipWrap{
    padding: 10px 16px;
    background-color: var(--theme-opacity-color);
    border-bottom: 1px solid rgba(0,0,0,0.06);
    .topTip{
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 18px;
        .saveMoneyIcon{
            width: 24px;
            height: 24px;
        }
        .saveMoneyContent{
            display: flex;
            align-items: center;
            flex: 1;
            padding-left: 16px;
            .saveMoneyTextMain{
                font-weight: 600;
                color: var(--theme-text-color);
            }
            .saveMoneyText{
                color: #000;
                span{
                    color: var(--theme-text-color);
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}

.payDetailContent{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
    background: rgb(246,246,248);
	.panelHeader{
		height: 48px;
		line-height: 48px;
		display: flex;
		background-color: #fff;
		border-bottom: 1px solid #f2f2f2;
		.headerLeft{
			width: 40px;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			i{
				margin-left: 4px;
				font-size: 16px;
			}
		}
		.headerMid{
			flex: 1;
			font-size: 17px;
			font-weight: 600;
			color: #000;
			text-align: center;
		}
		.headerRight{
			width: 40px;
			height: 48px;
		}
	}
	.panelCon{
		height: 0;
		flex: 1;
		overflow: auto;
	}
}

@media only screen and (min-width: 769px){
    .payDetailPage{
        padding-bottom: 1rem;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .checkoutBx{
        height: auto;
        overflow: auto;
        padding-bottom: 0;
    }
    .mobileSubmitBxSDF{
        position: static;
        width: auto;
        margin:0 0.8rem 1rem 0.8rem;
    }

    .saveMoneyText{
        font-size: 14px;
    }
}
