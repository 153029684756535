.pageHeaderBg{
	width: 100%;
	border-bottom: 1px solid #dedede;
	height: 48px;
	
	.pageHeader {
		width: 100%;
	
		.headerTop{
			height: 48px;
			display: flex;
			align-items: center;
			.backIcon {
				width: 38px;
				height: 48px;
				display: flex;
				align-items: center;
				padding-left: 13px;
				cursor: pointer;
				i{
					font-size: 18px;
					font-weight: bold;
				}
			}
				
			.homeIcon{
				width: 48px;
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: center;
				i{
					font-size: 24px;
				}
			}
			
			.storeLogo{
				width: 40px;
				height: 40px;
				border-radius: 20px;
				margin-right: 8px;
			}
			
			.storeName{
				width: 0;
				flex: 1;
				font-weight: 600;
				font-size: 17px;
				word-break: break-word;
				line-height: 21px;
				overflow : hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				cursor: pointer;
			}

			.storeSmallName{
				font-size: 14px;
				line-height: 18px;
			}
			.storeCenterName{
				text-align: center;
			}
			
			.rightBtns{
				margin-left: 5px;
				display: flex;
				align-items: center;
				height: 48px;
				padding-right: 10px;
				>div{
					cursor: pointer;
				}
				.accountImg{
					width: 28px;
					height: 28px;
					border-radius: 6px;
				}
				.languageInfo{
					height: 48px;
					display: flex;
					align-items: center;
					span{
						font-size: 12px;
						font-weight: 600;
						padding: 0 2px 0 8px;
					}
				}
				.languageInfoSdi{
					height: 20px;
					width: 20px;
					border-radius: 14px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 10px;
					box-shadow: 0 0 0 1.5px #dedede;
					.lingdang{
						width: 14px;
						height: 14px;
					}
					.iconList{
						font-size: 14px;
						color: #1C1B1F;
					}
				}
				.accountInfo{
					height: 20px;
					display: flex;
					box-shadow: 0 0 0 1.5px #dedede;
					border-radius: 14px;
					align-items: center;
					padding: 0 8px;
					span{
						display: block;
						max-width: 100px;
						font-size: 12px;
						padding-left: 3px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					i{
						font-size: 12px;
					}
				}
				.logInBtn{
					font-size: 12px;
					height: 20px;
					padding: 0 6px;
					box-shadow: 0 0 0 1.5px #ffb600;
					line-height: 20px;
					text-align: center;
					cursor: pointer;
					color: #ffb600;
					border-radius: 10px;
				}
			}
		}
	}
	.panelWrap{
		z-index: 62;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.25);
		.panelContainer{
			position: fixed;
			z-index: 63;
			top: 38px;
			box-shadow: 0 5px 12px 2px rgba(0,0,0,0.2);
			width: 110px;
			padding: 12px;
			opacity: 0.3;
			transform: scale(0.7,0.7);
			transition: all 0.2s;
			background-color: #fff;
			border-radius: 5px;
			transform-origin: 100% 0;
			&.show{
				opacity: 1;
				transform: scale(1,1);
			}
			.panelOption{
				font-size: 14px;
				line-height: 28px;
				border-bottom: 1px dashed #dedede;
				cursor: pointer;
				transition: color 0.2s;
				&:hover{
					color: var(--theme-text-color);
				}
			}
			&.listPanelContainer{
				width: 170px;
			}			
			.toggleLanguage{
				font-size: 16px;
				color: #333;
				.curLan{
					display: flex;
					align-items: center;
					padding-left: 2px;
					.iconLanguage{
						color: #FFB600;
						font-size: 18px;
					}
					.arrow{
						transition: all 0.3s;
						transform: rotate(0deg);
						font-size: 22px;
					}
					span{
						flex:1;
						padding-left: 8px;
					}
				}
				.languageOptionsOut{
					max-height: 0px;
					transition: all 0.2s;
					overflow: hidden;
					border-bottom: 1px solid #F2F2F2;
					padding-top: 10px;
					margin-bottom: 10px;
					.languageOptions{
						border-top: 1px solid #F2F2F2;
						padding: 8px 0;
						.languageOption{
							display: flex;
							align-items: center;
							height: 32px;
							padding-left: 5px;
							.languageOptionRadio{
								width: 8px;
								height: 8px;
								border-radius: 8px;
								box-shadow: 0 0 0 2px #FFB600;
								border: 3px solid #fff;
								background-color: #fff;
								margin-right: 9px;
								&.active{
									background-color: #FFB600;
								}
							}
							.languageOptionLabel{}
						}
					}
				}
				&.open{
					.curLan{
						.arrow{
							transform: rotate(-180deg);
						}
					}
					.languageOptionsOut{
						max-height: 160px;
					}
				}
			}
			.callWaiter{
				display: flex;
				align-items: center;
				padding: 0;
				cursor: pointer;
				color: #000;
				padding-left: 2px;
				.lingdang{
					width: 18px;
					height: 18px;
				}
				.text{
					margin-left: 6px;
					font-size: 16px;
				}
			}
		}
		.callWaiterPanelContainer{			
			position: fixed;
			z-index: 63;
			top: 50%;
			left: 50%;
			margin-left: -140px;
			margin-top: -115px;
			box-shadow: 0 5px 12px 2px rgba(0,0,0,0.2);
			width: 280px;
			padding: 12px 12px 20px;
			opacity: 0.3;
			transform: scale(0.7,0.7);
			transition: all 0.2s;
			background-color: #fff;
			border-radius: 10px;
			transform-origin: 100% 0;
			&.show{
				opacity: 1;
				transform: scale(1,1);
			}
			.callWaiterPanelInner{
				.title{
					margin: 10px 0 20px;
					font-size: 16px;
					text-align: center;
				}
				.list{
					list-style: none;
					padding: 0;
					li{
						height: 36px;
						line-height: 36px;
						text-align: center;
						margin: 10px 0;
						font-size: 14px;
						border-radius: 18px;
						background: rgba(0,0,0,0.1);
					}
				}
			}
		}
	}
	
	&.mainPageHeader{
		border-bottom: none;
		height: auto;
	}
}

.requestSentIcon{	
	text-align: center;
	margin-bottom: 30px;
	i{
		font-size: 40px;
		color: #f3cb29;
	}	
}
.successCallWaiter{
	text-align: center;
	margin-bottom: 30px;
	i{
		font-size: 40px;
		color: #598a22;
	}			
}
.deliveryAndAmount{
	font-size: 12px;
	color: #828282;
	.separatorBtn{
		padding: 4px;
	}
}

@media screen and (min-width:769px) {
	.pageHeaderBg{
		.pageHeader{
			.headerTop{
				.homeIcon{
					margin-left: 5px;
					i{
						font-size: 28px;
					}
				}
				.storeName{
					flex: 3;
					font-size: 20px;
					line-height: 26px;
				}
				.storeSmallName{
					font-size: 18px;
					line-height: 22px;
				}
				.rightBtns{
					.logInBtn{
						font-size: 13px;
						line-height: 22px;
						height: 22px;
						border-radius: 12px;
						padding: 0 10px;
					}
					.languageInfo{
						justify-content: center;
						padding-left: 5px;
						span{
							font-size:13px;
						}
					}
					.accountInfo{
						font-weight: 600;
					}
				}
			}
			.searchBar{
				flex: 2;
				max-width: 400;
				margin-left: 20px;
				margin-right: 20px;
				position: relative;
				.iconSearch{
					position: absolute;
					top: 11px;
					left: 12px;
				}
				.iconReset{
					position: absolute;
					right: 12px;
					top: 12px;
					transform: rotate(45deg);
					cursor: pointer;
				}
				input{
					padding: 0 30px 0 36px;
					border-radius: 18px;
				}
			}
		}
		
		&.mainPageHeader{
			.pageHeader{
				padding: 12px 12px 0;
			}
		}
	}
    
}

@media screen and (min-width: 1200px) {
	.pageHeaderBg,.pageHeaderBg.mainPageHeader{
		.pageHeader{
			max-width: 1480px;
			margin: 0 auto;
			padding-left: 70px;
			padding-right: 70px;
			.headerTop{
				.homeIcon{
					padding-left: 0;
					margin-left: -9px;
				}
				.rightBtns{
					padding-right: 0;
				}
				.backIcon{
					margin-left: -10px;
				}
			}
		}
		
	}
	
}

.floatBag {
	position: fixed;
	left: 0px;
	bottom: 0px;
	right: 0px;
	height: 66px;
	z-index: 61;
	padding-top: 5px;

	.floatBagBtn{
		width: 90%;
		margin: 0 auto;
		height: 46px;
		background: var(--theme-color);
		border-radius: 10px;
		box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.15);
		display: flex;
		padding: 0 10px;
		
		.bagBox {
			position: relative;
			width: 40px;
			height: 46px;
			padding-top: 2px;
			i.icon{
				font-size: 40px;
				color: var(--color-btn-text);
			}
			.goodsQty {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				line-height: 53px;
				text-align: center;
				color: var(--theme-text-color);
				font-weight: 600;
				font-size: 12px;
			}
			&.bagBoxShake {
				transform-origin: 50% 50%;
				animation: shake 0.2s;
				transition: all 0.2s;
			}
			&.sdi {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				i.icon{
					font-size: 24px;
					color: var(--color-btn-text);
				}
				.tableNameCon{
					height: 16px;
					width: 40px;
					position: relative;
				}
				.tableName{
					font-size: 12px;
					line-height:16px;
					font-weight: 600;
					color: var(--color-btn-text);
					position: absolute;
					bottom: 0;
					left:0;
					width: 100px;
				}
			}
		}
		
		.priceInfo {
			display: flex;
			flex: 1;
			justify-content: center;
			font-size: 17px;
			line-height: 24px;
			color: var(--color-btn-text);
			text-align: center;
			align-items: center;
			font-weight: 600;
		
			.btnName {
				height: 24px;
				overflow: hidden;
				text-align: center;
		
				>div {
					margin-top: 0;
					height: 24px;
					overflow: hidden;
					transition: margin-top 0.5s;
		
					&.btnNameTrans {
						margin-top: -24px;
					}
				}
				
			}
			.pricePonit {
				margin: 0 5px;
			}
		}
	}
	
}
@keyframes shake {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(15deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-15deg);
    }

    100% {
        transform: scale(0deg);
    }
}
.appLoginDialogWrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
    opacity: 0;
    transition: opacity 0.22s;

    .appLoginDialog {
        width: 250px;
        height: 160px;
        position: absolute;
        top: calc(50% - 80px);
        left: calc(50% - 125px);
        background-color: #FFF;
        border-radius: 12px;
        transform: scale(0.7, 0.7);
        transition: transform 0.2s;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;

        .appLoginDialogContent {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: normal;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .appLoginDialogFooter {
            height: 42px;
            line-height: 42px;
            display: flex;

            .appLoginDialogBtn {
                flex: 1;
                text-align: center;
                color: #678be0;
				font-size: 14px;

                &:nth-child(2) {
                    border-left: 1px solid rgba(0, 0, 0, 0.1);
					font-weight: 500;
                }
            }
        }
    }

    &.show {
        opacity: 1;

        .appLoginDialog {
            transform: scale(1, 1);
        }
    }
}